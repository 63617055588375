* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    margin: 0;
    color: $dark-grey;
    font-family: Arial, Helvetica, sans-serif;
    font-size: $m-size;
    line-height: 1.6;
    background-color: $light-yellow;
}

input, select, textarea { font-size: 100%; }

button {
    cursor: pointer;
}

button:disabled {
    cursor: default;
}

.is-active {
    font-weight: bold;
}