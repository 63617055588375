.profile-container {
    max-width: 100%;
    display: flex;
}

.profile-info {
    max-width: 300px;
    display: none;

    @media (min-width: $desktop-breakpoint - .01rem) {
        display: block;
    }
}

.mobile-profile-info {
    max-width: 300px;
    display: flex;
    flex-direction: row;
 
    @media (min-width: $desktop-breakpoint - .01rem) {
        display: none;
    }
}

.profile--pic {
    max-width: 175px;
}

.mobile-profile--pic {
    max-width: 100px;
}

.profile--label {
    background: $label-background;
    font-size: $m-size;
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.profile-action-box {
    width: 100%;

    textarea {
        width: 95%;
    }
}

.profile-content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.profile-comments-box {
    width: 100%;
}

.profile-basic {
    max-width: 90px;
    text-align: center;
    margin-top: $s-size;

    p {
        padding: 0;
        margin: 0;
    }
    .profile--pic-small {
        max-width: 80px;
    }
}

.profile-head {
    background-color: $light-brown;
    border: solid 1px $medium-brown;
    border-radius: 0.5em;
    padding: 1em;
    display: flex;

    .profile--pic-small {
        align-self: flex-start;
        max-width: 80px;
    }

    .profile-head-message {
        font-size: $font-size-large;
    }

    .profile-head-secondary-message {
        font-size: $font-size-small;
        color: $grey;
        flex-direction: row;
    }
}

.profile-head-content {
    padding-left: 1em;
}

.profile--text {
    background: $description-background;
    font-size: $m-size;
    padding: 0;
    margin: 0;
}

.notificationCount {
    padding-left: $xxs-size;
}