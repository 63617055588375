header {
    text-align: center;
    position: relative;
    width: 100%;
}


.page-heading {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    font-size: $m-size;
    margin-bottom: $m-size;
}