.big-button {
    background: $purple;
    border: none;
    border-bottom: .6rem solid darken($purple, 10%);
    color: white;
    font-weight: bold;
    font-size: $l-size;
    margin-bottom: $s-size;
    padding: 2.4rem;
    width: 100%;
}

.big-button:disabled {
    opacity: .5;
}


.button {
    border: none;
    font-weight: 500;
    padding: $s-size;
}

.button--link {
    background: none;
    border: none;
    color: $off-white;
    padding: 0;
}


.button--success {
    background: $green-success;
    color: $white;
    font-size: $m-size;
    text-decoration: none;
}


@media (min-width: $desktop-breakpoint) {

    .big-button {
        margin-bottom: $xl-size;
    }
}