.search-query-input {
    padding: 0px 14px;
    margin-bottom: 0;
    height: 32px;
    border-radius: 15px;
    outline: none;
}

.friend-search-box {
    margin-top: $m-size;
    font-size: $font-size-medium;
    color: $grey;
    padding: 12px;
    border: 1px solid $dark-brown;
    background-color: $light-yellow;
}

.friend-search-box textarea {
    max-width: 100%;
}