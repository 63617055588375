.message-container {
    display: flex;
    margin: $s-size;
    padding: $s-size;
    background-color: $off-white;
    flex-direction: column;
}

.message-container-reply {
    @extend .message-container;
    width: 85%;
    background-color: darken($off-white, 10%);
    margin-left: auto;
}

.message-content-container {
    display: flex;
    flex-direction: row;
}

.message-subject {
    font-weight: 600;
}

.message-content {
    white-space: pre-wrap;  
}

.message-content-container-reply {
    @extend .message-content-container;
    background-color: darken($off-white, 10%);
}

.message-byline {
    font-size: $font-size-xsmall;
    color: $grey;
    margin-left: auto;
}

.message-profile-pic {
    max-width: 80px;
    padding-right: $s-size;
}

.message-button {
    margin-left: auto;
}