.notification {
    display: flex;
    justify-content: space-between;
    height: $l-size;
}

.notification-text {
    width: 70%;
}

.notification-label {
    font-size: $ml-size;
    margin-top: $s-size;
}

.notification-remove {
}