// Colors
$dark-grey: #333;
$grey: #666;
$light-blue: #1c88bf;
$off-white: #f7f7f7;
$off-grey: #E8E6E6;
$medium-grey: #888;
$light-grey: #999;
$dark-blue: #364051;
$white: #fff;
$black: #000;
$light-yellow: #efebd6;
$light-brown: #f9f6f4;
$dark-brown: #b7afa3;
$medium-brown: #c9a798;
$light-green: #d3e3de;
$green-success: green;

$label-background: #cadbbd;
$description-background: #fafff3;
// TODO - fix these!
$purple: #000;

// Font Size
$font-size-xsmall: 1rem;
$font-size-small: 1.4rem;
$font-size-medium: 1.6rem;
$font-size-large: 1.8rem;

// Spacing

$xxs-size: 0.8rem;
$xs-size: 1rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$ml-size: 2.4rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
$all-breakpoint: 0.1rem;