.navbar {
    max-width: 100rem;
    background-color: $black;
    color: $medium-grey;
    margin: 0 auto;
    padding: $xs-size $xs-size;
    height: $xl-size;
    
    @media (min-width: $desktop-breakpoint - .01rem) {
        height: 100%;
    }
}

.navbar__links {
    position: fixed;
    width: 100%;
    background-color: $white;
    height: 100vh;
    padding: 16px;
    left: -100%;
    transition: all .2s;
    list-style: none;
    text-align: left;

    @media (min-width: $desktop-breakpoint - .01rem) {
        all: unset;
        list-style: none;
        display: flex;
    }
}

.navbar__link {
    margin-bottom: $s-size;
    margin-left: $s-size;
    a {
        color: $black;
        text-decoration: none;
        font-size: $font-size-large;

        @media (min-width: $desktop-breakpoint - .01rem) {
            color: $light-grey;
        }
    }
    a:hover {
        color: $light-grey;

        @media (min-width: $desktop-breakpoint - .01rem) {
            color: $white;
        }
    }

    @media (min-width: $desktop-breakpoint - .01rem) {
        margin-bottom: 0;
        margin-right: 16px;
    }

}

.navbar__toggler {
    display: none;
}


.navbar__toggler:checked ~ .navbar__links {
    top: $xl-size;
    left: -20%;
    padding-left: 25%;
    border-bottom: 1px solid white;
}

.navbar__burger {
    position: absolute;
    font-size: $font-size-large;
    // text-shadow: 2px 2px 2px 4px rgba(31, 31, 31, 0.658);
    top: 0;
    right: 0;
    margin-right: 16px;
    height: 100%;
    display: flex;
    align-items: center;

    @media (min-width: $desktop-breakpoint - .01rem) {
        display: none;
    }
}

nav {
    
    @media (min-width: $desktop-breakpoint - .01rem) {
        display: flex;
        grid-column: 3/4;
        justify-content: flex-end;
        align-items: center;
    }
}
